import get from 'lodash.get';

export function getTrackDetailsSelector(trackId) {
  return function trackDetailsSelector(state) {
    const track = get(state, `search.tracks.${trackId}`, {});
    const availableMarkets = track.available_markets || [];
    const playHref = get(track, 'external_urls.spotify', '');
    return {
      availableMarkets,
      playHref,
    };
  };
}

export function currentTrackIdSelector(state) {
  return get(state, `trackDetails.currentTrackId`);
}
