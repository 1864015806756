import React, { Component } from 'react';
import { array } from 'prop-types';
import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography,
} from 'react-simple-maps';
import geographies from './world-50m';
import { Box } from '../ui';

// uses to make spotify country codes understandable to react-simple-maps codes
import { codes } from './alpha3alpha2Map';

const wrapperStyles = {
  width: '100%',
  maxWidth: 980,
};

const selectedCountry = {
  fill: '#607D8B',
  stroke: '#607D8B',
  strokeWidth: 0.75,
  outline: 'none',
};

const unselectedCountry = {
  fill: '#ECEFF1',
  stroke: '#607D8B',
  strokeWidth: 0.75,
  outline: 'none',
};

export function WorldMap({ countries }) {
  return (
    <Box style={wrapperStyles}>
      <ComposableMap
        projectionConfig={{
          scale: 205,
          rotation: [-11, 0, 0],
        }}
        width={980}
        height={551}
        style={{
          width: '100%',
          height: 'auto',
        }}
      >
        <ZoomableGroup center={[0, 20]} disablePanning>
          <Geographies geography={geographies}>
            {(geographies, projection) =>
              geographies.map((geography, i) => {
                const alpha2Code = codes[geography.id];
                let style = unselectedCountry;
                if (countries.includes(alpha2Code)) {
                  style = selectedCountry;
                }
                return (
                  geography.id !== 'ATA' && (
                    <Geography
                      key={i}
                      geography={geography}
                      projection={projection}
                      style={{
                        default: style,
                      }}
                    />
                  )
                );
              })
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
    </Box>
  );
}

WorldMap.propTypes = {
  countries: array.isRequired,
};
