import { createRenderer } from 'fela';

export function createStylesRenderer() {
  const renderer = createRenderer({});

  renderer.renderStatic(`
    /* Define the "system" font family */
    @font-face {
      font-family: system;
      font-style: normal;
      font-weight: 400;
      src: local(".SFNSText-Light"), local(".HelveticaNeueDeskInterface-Light"), local(".LucidaGrandeUI"), local("Ubuntu Light"), local("Segoe UI Light"), local("Roboto-Light"), local("DroidSans"), local("Tahoma");
    }
    html, body, #root {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
    }`);

  return renderer;
}
