import get from 'lodash.get';

export function trackDataSelector(track) {
  const trackName = get(track, 'name', '');
  const imgSrc = get(track, 'album.images[1].url', '');
  const artist = get(track, 'album.artists[0].name', '');
  const trackId = get(track, 'id', '');
  return {
    trackName,
    imgSrc,
    artist,
    trackId,
  };
}

export function tracksSelector(state) {
  const data =  get(state, 'search.tracks', {});
  return Object.values(data);
}

export function isFetchingSelector(state) {
  return get(state, 'search.isFetching', false);
}
