import React from 'react';
import { string, object, oneOfType, element } from 'prop-types';
import { Base } from '../Base';
import { padding } from '../Spacer';

const styles = {
  borderRadius: '4px',
  ...padding(),
  appearance: 'none',
  borderStyle: 'solid',
  borderWidth: '1px',
  lineHeight: 1,
  margin: '0',
  //boxShadow: '0 1px 6px 0 rgba(32,33,36,0.28)',
  borderColor: 'rgba(223,225,229)',
  //backgroundColor: 'rgba(223,225,229,0.1)',
  fontSize: '16px',
  height: '100%',
};

export function Input({
  as,
  type,
  placeholder,
  value,
  label,
  extend,
  onChange,
  ...props
}) {
  return (
    <Base
      as={as || 'input'}
      value={value}
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      extend={{ ...styles, ...extend }}
      {...props}
    />
  );
}

Input.defaultProps = {
  type: 'text',
};

Input.propTypes = {
  as: oneOfType([string, element]),
  type: string,
  extend: object,
};
