import React from 'react';
import { Box } from '../Box';
import { element, node, object, oneOfType, string } from 'prop-types';

const styles = { display: 'flex', flexGrow: 1, flexWrap: 'wrap' };

export function Flex({ as, children, extend, ...props }) {
  return (
    <Box as={as} extend={{ ...styles, ...extend }} {...props}>
      {children}
    </Box>
  );
}

Flex.propTypes = {
  as: oneOfType([string, element]),
  children: node,
  extend: object,
};
