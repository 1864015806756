import React from 'react';
import { useFela } from 'react-fela';
import { string, node, object, oneOfType, element } from 'prop-types';

const styles = {
  boxSizing: 'border-box',
};

export function Base({ as, children, extend, ...props }) {
  const { css } = useFela(props);
  const El = as || 'div';
  return (
    <El {...props} className={css(styles, extend)}>
      {children}
    </El>
  );
}

Base.propTypes = {
  as: oneOfType([string, element]).isRequired,
  extend: object.isRequired,
  children: node,
};
