//  naming them by their closest hundredths
// based on https://ricostacruz.com/til/css-media-query-breakpoints
const sizes = {
  _4: 480,
  _7: 768,
  _9: 992,
  _12: 1200,
};
/**
 * Mobile first
 * @type {{viewPort4: string, viewPort7: string, viewPort12: string, viewPort9: string}}
 */
export const Breakpoints = {
  viewPort4: `@media (min-width: ${sizes._4}px)`,
  viewPort7: `@media (min-width: ${sizes._7}px)`,
  viewPort9: `@media (min-width: ${sizes._9}px)`,
  viewPort12: `@media (min-width: ${sizes._12}px)`,
};
