import React from 'react';
import { useSelector } from 'react-redux';
import { navigate } from '@reach/router';
import { Flex, Spinner } from '../ui';
import {
  tracksSelector,
  trackDataSelector,
  isFetchingSelector,
} from './selectors';
import { TrackCard } from './TrackCard';


function SearchResults() {
  // useSelector hook access the redux state and extracts the state from the
  // selector
  const isFetching = useSelector(isFetchingSelector);
  const tracks = useSelector(tracksSelector);

  if (isFetching) {
    return <Spinner />;
  }

  return (
    <Flex extend={{ justifyContent: 'center' }}>
      {tracks.map(track => {
        const { trackName, artist, imgSrc, trackId } = trackDataSelector(track);
        const onClickHandler = () => {
          navigate(`/trackdetails/${trackId}`)
        };
        return (
          <TrackCard
            key={trackId}
            artist={artist}
            trackName={trackName}
            imgSrc={imgSrc}
            onClick={onClickHandler}
          />
        );
      })}
    </Flex>
  );
}

SearchResults.propTypes = {};

export { SearchResults };
