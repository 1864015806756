import React from 'react';
import { Base } from '../Base';
import { string, node, object, oneOfType, element } from 'prop-types';

const styles = { display: 'block' };

export function Box({ as, children, extend, ...props }) {
  return (
    <Base as={as || 'div'} extend={{ ...styles, ...extend }} {...props}>
      {children}
    </Base>
  );
}

Box.propTypes = {
  as: oneOfType([string, element]),
  children: node,
  extend: object,
};
