import React, { useContext } from 'react';
import { node } from 'prop-types';
import { RendererProvider, RendererContext } from 'react-fela';
import { createStylesRenderer } from './renderer';

const renderer = createStylesRenderer();

export function UiProvider({ children }) {
  const contextRenderer = useContext(RendererContext);
  if (contextRenderer) {
    return children;
  }
  return <RendererProvider renderer={renderer}>{children}</RendererProvider>;
}

export { renderer };

UiProvider.propTypes = {
  children: node.isRequired,
};
