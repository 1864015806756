import React from 'react';
import { Base } from '../Base';
import { oneOf, node, object } from 'prop-types';

const HEADING_TYPES = {
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  H6: 'h6'
};

const styles = {
  display: 'inline-block',
  lineHeight: 1,
  fontFamily: 'system',
  margin: 0,
};

export function Heading({ type, children, extend, ...props }) {
  return (
    <Base as={type} extend={{ ...styles, ...extend }} {...props}>
      {children}
    </Base>
  );
}

export { HEADING_TYPES };

Heading.propTypes = {
  type: oneOf([...Object.values(HEADING_TYPES)]).isRequired,
  children: node.isRequired,
  extend: object,
};

Heading.defaultProps = {
  type: HEADING_TYPES.H1,
};
