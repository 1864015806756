import { hot } from 'react-hot-loader';
import React from 'react';
import { Router } from '@reach/router';
import { Box } from './ui';
import { Header } from './Header';
import { SearchPage } from './SearchPage';
import { TrackDetails } from './TrackDetails';

function App() {
  return (
    <Box>
      <Header />
      <Router>
        <SearchPage path="/"/>
        <SearchPage path="/search/:query" />
        <TrackDetails path="/trackdetails/:trackId"/>
      </Router>
    </Box>
  );
}

export default hot(module)(App);
