import { makeActionCreator } from '../helpers/makeActionCreator';
import { fetchApi, getTracksFromResult } from '../helpers';

export const FETCH_TRACKS = {
  REQUEST: 'FETCH_SONGS_REQUEST',
  ERROR: 'FETCH_SONGS_ERROR',
  SUCCESS: 'FETCH_SONGS_SUCCESS',
};

export const searchTracksFetch = makeActionCreator(
  FETCH_TRACKS.REQUEST,
  'query'
);
export const searchTracksSuccess = makeActionCreator(
  FETCH_TRACKS.SUCCESS,
  'tracks'
);
export const searchTracksError = makeActionCreator(FETCH_TRACKS.ERROR, 'error');

export function fetchSongs({ query }) {
  return async function(dispatch) {
    dispatch(searchTracksFetch(query));
    const result = await fetchApi({ query });
    if (result.error) {
      dispatch(searchTracksError(result.error));
    } else {
      const tracks = getTracksFromResult({ result });
      dispatch(searchTracksSuccess(tracks));
    }
  };
}
