import { SPACING } from './Spacer';

/**
 * multiple {Number} default 1
 */
export function spacingPx(multiple = 1) {
  return `${SPACING * multiple}px`;
}

/**
 * multiple {Number} default 1
 */
export function padding(multiple) {
  return { padding: `${spacingPx(multiple)}` };
}

/**
 * multiple {Number} default 1
 */
export function paddingVertical(multiple) {
  return { padding: `${spacingPx(multiple)} 0` };
}

/**
 * multiple {Number} default 1
 */
export function paddingHorizontal(multiple) {
  return { padding: `0 ${spacingPx(multiple)}` };
}

/**
 * multiple {Number} default 1
 */
export function margin(multiple) {
  return { margin: `${spacingPx(multiple)}` };
}

/**
 * multiple {Number} default 1
 */
export function marginVertical(multiple) {
  return { margin: `${spacingPx(multiple)} 0` };
}

/**
 * multiple {Number} default 1
 */
export function marginHorizontal(multiple) {
  return { margin: `0 ${spacingPx(multiple)}` };
}
