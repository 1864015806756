import React from 'react';
import { string } from 'prop-types';
import { useDispatch } from 'react-redux';
import { navigate } from '@reach/router';
import { useInput } from '../hooks/useInput';
import { Flex, Input, Button, paddingVertical } from '../ui';
import { fetchSongs } from './actions';

function SearchForm({ urlQuery }) {
  const [query, handleOnChange] = useInput(urlQuery);
  const dispatch = useDispatch();

  return (
    <Flex extend={{ alignItems: 'center', ...paddingVertical() }} as="form">
      <Input
        extend={{ flexGrow: '1' }}
        onChange={handleOnChange}
        value={query}
      />
      <Button
        onClick={ev => {
          ev.preventDefault();
          dispatch(fetchSongs({ query }));
          navigate(`/search/${query}`);
        }}
      >
        Search
      </Button>
    </Flex>
  );
}

SearchForm.defaultProps = {
  urlQuery: '',
};

SearchForm.propTypes = {
  urlQuery: string,
};



export { SearchForm };
