import React from 'react';
import { Base } from '../Base';
import { string, node, object, oneOfType, element } from 'prop-types';

const styles = {
  lineHeight: 1.5,
  fontFamily: 'system',
  margin: 0,
};

export function Text({ as, children, extend, ...props }) {
  return (
    <Base as={as || 'p'} extend={{ ...styles, ...extend }} {...props}>
      {children}
    </Base>
  );
}

Text.propTypes = {
  as: oneOfType([string, element]),
  children: node.isRequired,
  extend: object,
};
