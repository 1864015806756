import { FETCH_TRACKS } from './actions';

function normalizeTracks(tracksResponse) {
  if (!tracksResponse) {
    return {};
  }
  const { items } = tracksResponse;
  return items.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});
}
const search = (state = {}, action) => {
  switch (action.type) {
    case FETCH_TRACKS.REQUEST:
      return { ...state, query: action.query, isFetching: true, result: null };
    case FETCH_TRACKS.SUCCESS:
      // TODO implement paging
      return { ...state, tracks: normalizeTracks(action.tracks), isFetching: false };
    case FETCH_TRACKS.ERROR:
      return { ...state, error: action.error, isFetching: false };
    default:
      return state;
  }
};

export default search;
