import React from 'react';
import { string, func } from 'prop-types';
import { Card, Text, Image, Flex, margin, spacingPx, SPACING } from '../ui';

const commonTextStyles = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '0.875rem',
};

const SIZE = 288;
const maxWidth = `${SIZE + SPACING * 2}px`;
function TrackCard({ artist, imgSrc, trackName, onClick }) {
  return (
    <Flex
      as="a"
      extend={{
        flexGrow: '0',
        flexDirection: 'column',
        ...margin(),
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <Card extend={{ textAlign: 'center', maxWidth }}>
        <Image
          extend={{ display: 'block', marginBottom: spacingPx(0.5) }}
          src={imgSrc}
          alt={`${artist} ${trackName} image cover`}
        />
        <Text extend={commonTextStyles}>{trackName}</Text>
        <Text extend={{ ...commonTextStyles, color: '#777' }}>{artist}</Text>
      </Card>
    </Flex>
  );
}

TrackCard.propTypes = {
  artist: string.isRequired,
  imgSrc: string.isRequired,
  trackName: string.isRequired,
  onClick: func.isRequired,
};

export { TrackCard };
