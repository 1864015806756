import React, { useState } from 'react';

export function useInput(initialValue) {
  const [value, setValue] = useState(initialValue);

  function handleOnChange(ev) {
    const { value } = ev.target;
    setValue(value);
  }

  // returning array permits us for a generic hook
  return [
    value,
    handleOnChange,
  ];
}
