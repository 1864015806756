import React from 'react';
import { element, node, object, oneOf, func } from 'prop-types';
import { Base } from '../Base';
import { baseStyles, outlineStyles } from './styles';

const BUTTON_TYPES = {
  OUTLINE: 'outline'
};

function getTypeStyles({ type }) {
  if (type === BUTTON_TYPES.OUTLINE) {
    return outlineStyles;
  }
  return {};
}

export function Button({ as, children, extend, onClick, type, ...props }) {
  const styles = { ...baseStyles, ...getTypeStyles({ type }), ...extend };
  return (
    <Base onClick={onClick} as={as || 'button'} extend={styles} {...props}>
      {children}
    </Base>
  );
}
export { BUTTON_TYPES };

Button.propTypes = {
  as: element,
  children: node,
  extend: object,
  onClick: func,
  type: oneOf([BUTTON_TYPES.OUTLINE]),
};
