import React from 'react';
import { Box } from '../Box';
import { renderer } from '../UiProvider';

const keyframe = () => ({
  '0%': { transform: 'scale(0.0)' },
  '50%': { transform: 'scale(1.0)' },
  '100%': { transform: 'scale(0.0)' }
});

const animationName = renderer.renderKeyframe(keyframe, {});

const spinner = {
  width: '40px',
  height: '40px',
  position: 'relative',
  margin: '100px auto',
};

const doubleBounce1 = {
  width: '100%',
  height: '100%',
  borderRadius: '50%',
  backgroundColor: '#333',
  opacity: '0.6',
  position: 'absolute',
  top: '0',
  left: '0',
  animation: `${animationName} 2.0s infinite ease-in-out`,
};

const doubleBounce2 = {
  ...doubleBounce1,
  animationDelay: '-1.0s',
};

export function Spinner() {
  return (
    <Box extend={spinner}>
      <Box extend={doubleBounce1}></Box>
      <Box extend={doubleBounce2}></Box>
    </Box>
  );
}
