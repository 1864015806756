import React from 'react';
import { string } from 'prop-types';
import { SearchBar } from '../SearchBar';
import { SearchResults } from '../SearchResults';

export function SearchPage({ query }) {
  return (
    <>
      <SearchBar urlQuery={query}/>
      <SearchResults />
    </>
  );
}

SearchPage.defaultProps = {
  query: ''
};

SearchPage.propTypes = {
  query: string
};
