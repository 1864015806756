import React from 'react';
import { Base } from '../Base';
import { string, object, element } from 'prop-types';

const styles = { display: 'block' };

export function Image({ as, src, alt, extend, ...props }) {
  return (
    <Base
      as={as || 'img'}
      src={src}
      extend={{ ...styles, ...extend }}
      {...props}
    />
  );
}

Image.propTypes = {
  as: element,
  src: string.isRequired,
  alt: string.isRequired,
  extend: object,
};
