import React from 'react';
import { Base } from '../Base';
import { string, element, object, node } from 'prop-types';

const styles = {
  display: 'inline-block',
  color: 'rgb(51, 51, 238)',
  fontFamily: 'system',
};

export function Link({ as, children, extend, href, ...props }) {
  return (
    <Base
      as={as || 'a'}
      href={href}
      extend={{ ...styles, ...extend }}
      {...props}
    >
      {children}
    </Base>
  );
}

Link.propTypes = {
  as: element,
  href: string.isRequired,
  extend: object,
  children: node,
};
