import React from 'react';
import { number } from 'prop-types';
import { Flex } from '../Flex';

// automatically horizontal/vertical depending on flex-direction
export function FlexSpacer({ size }) {
  return (
    <Flex
      extend={{
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: `${size}px`,
      }}
    />
  );
}

FlexSpacer.propTypes = {
  size: number.isRequired,
};
