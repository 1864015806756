const activeBackgroundColor = '#3253af';
const backgroundColor = '#5174d1';

const almostBlack = 'rgb(64,63,67)';
const almostBlackAction = 'rgb(64,63,67, 0.05)';

export const baseStyles = {
  fontFamily: 'system',
  fontSize: '14px',
  paddingLeft: '40px',
  paddingRight: '40px',
  paddingTop: '16px',
  paddingBottom: '16px',
  color: 'white',
  backgroundColor,
  display: 'inline-block',
  textAlign: 'center',
  margin: '0px',
  textDecoration: 'none',
  borderWidth: '0px',
  borderRadius: '4px',
  transition: 'all 0.3s ease-out',
  transitionProperty: 'background, fill, stroke, color, border-color',
  fontWeight: 400,
  lineHeight: 1.2,
  cursor: 'pointer',
  ':hover': {
    backgroundColor: activeBackgroundColor,
  },
  ':focus': {
    outline: `2px solid rgba(81,116,209,.33)`,
    ':active': {
      backgroundColor: activeBackgroundColor,
    },
  },
};

export const outlineStyles = {
  backgroundColor: 'transparent',
  color: almostBlack,
  borderColor: almostBlack,
  borderWidth: '1px',
  ':hover': {
    background: almostBlackAction,
  },
  ':focus': {
    outline: `2px solid rgba(64,63,67,.33)`,
    ':active': {
      background: almostBlackAction,
    },
  },
};
