import React from 'react';
import { useSelector } from 'react-redux';
import {
  Flex,
  Box,
  Button,
  padding,
  margin,
  paddingVertical,
  BUTTON_TYPES,
  Heading,
  HEADING_TYPES,
} from '../ui';
import { WorldMap } from '../WorldMap';
import { getTrackDetailsSelector } from './selectors';

const SECTION_COLORS = 'rgb(0, 198, 66, 0.2)';

function TrackDetails({ trackId }) {
  const trackDetailsSelector = getTrackDetailsSelector(trackId);
  const { playHref, availableMarkets } = useSelector(trackDetailsSelector);
  const onClick = () => {
    window.open(playHref);
  };
  return (
    <>
      <Heading
        type={HEADING_TYPES.H2}
        extend={{ ...padding(), backgroundColor: '' }}
      >
        Track Details
      </Heading>
      <Flex extend={{ flexDirection: 'row', flexWrap: 'nowrap' }}>
        <Flex
          extend={{
            ...padding(),
            ...margin(),
            backgroundColor: SECTION_COLORS,
            flexWrap: 'nowrap',
            flexDirection: 'column',
          }}
        >
          <Heading extend={{ ...paddingVertical() }} type={HEADING_TYPES.H3}>
            Actions
          </Heading>
          <Button type={BUTTON_TYPES.OUTLINE} onClick={onClick}>
            Play track on spotify
          </Button>
        </Flex>
        <Flex
          extend={{
            ...padding(),
            ...margin(),
            maxWidth: '70%',
            backgroundColor: SECTION_COLORS,
          }}
        >
          <Heading type={HEADING_TYPES.H3}>Available Markets</Heading>
          <WorldMap countries={availableMarkets} />
        </Flex>
      </Flex>
    </>
  );
}

TrackDetails.propTypes = {};

export { TrackDetails };
