import React from 'react';
import { Flex, Heading, padding } from '../ui';

export const Header = () => (
  <Flex
    extend={{
      flexDirection: 'column',
      backgroundColor: '#96ccff',
      width: '100%',
      borderBottomColor: 'rgba(0,0,0,.1)',
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      ...padding()
    }}
  >
    <Heading>metasong</Heading>
  </Flex>
);
