import React from 'react';
import { Box } from '../Box';
import { padding } from '../Spacer';
import { string, node, object, oneOfType, element } from 'prop-types';

const styles = {
  borderRadius: '2px',
  boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
  ...padding(0.5),
  // source https://codepen.io/sdthornton/pen/wBZdXq
  ':hover': {
    boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)'
  }
};

export function Card({ as, children, extend, ...props }) {
  return (
    <Box as={as || 'div'} extend={{ ...styles, ...extend }} {...props}>
      {children}
    </Box>
  );
}

Card.propTypes = {
  as: oneOfType([string, element]),
  children: node,
  extend: object,
};
