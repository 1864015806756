import React from 'react';
import { number } from 'prop-types';
import { Flex } from '../Flex';
import { Breakpoints } from '../Breakpoints';
import { SPACING } from './Spacer';

const { viewPort4, viewPort7, viewPort9, viewPort12 } = Breakpoints;

/**
 *
 */
export function ResponsiveSpacer({ baseSize }) {
  return (
    // automatically horizontal/vertical depending on flex-direction
    <Flex
      extend={{
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: `${baseSize}px`,
        [viewPort4]: {
          flexBasis: `${baseSize * 1.5}px`,
        },
        [viewPort7]: {
          flexBasis: `${baseSize * 1.5}px`,
        },
        [viewPort9]: {
          flexBasis: `${baseSize * 2}px`,
        },
        [viewPort12]: {
          flexBasis: `${baseSize * 2}px`,
        },
      }}
    />
  );
}

ResponsiveSpacer.propTypes = {
  // Since this is mobile first, what is the minimum size for mobile
  baseSize: number,
};

ResponsiveSpacer.defautProps = {
  baseSize: SPACING
};
