import React from 'react';
import { string } from 'prop-types';
import { Text, Flex, padding } from '../ui';
import { SearchForm } from './SearchForm';

export function SearchBar({ urlQuery }) {
  return (
    <Flex
      extend={{
        flexDirection: 'column',
        backgroundColor: '#96ccff',
        width: '100%',
        ...padding(),
      }}
    >
      <Text>Search for tracks</Text>
      <SearchForm urlQuery={urlQuery} />
    </Flex>
  );
}

SearchBar.propTypes = {
  urlQuery: string
};

SearchBar.defaultProps = {
  urlQuery: ''
};