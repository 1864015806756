import fetch from 'isomorphic-unfetch';
import isEmpty from 'lodash.isempty';

const apiEndpoint =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3001/?queryString='
    : '/api/search/';

export async function fetchApi({ query, limit = '50', offset = '0' } = {}) {
  const res = await fetch(
    `${apiEndpoint}${query}&limit=${limit}&offset=${offset}`
  );
  if (!res.ok) {
    console.error(res.statusText);
    return { error: res.statusText, query };
  }
  try {
    return res.json();
  } catch (err) {
    return { error: err.message };
  }
}

export function getItemsFromResult({ clientResult, serverResult }) {
  if (!isEmpty(clientResult) && !isEmpty(clientResult.items)) {
    return clientResult.items;
  }
  if (!isEmpty(serverResult) && !isEmpty(serverResult.items)) {
    return serverResult.items;
  }
  return null;
}

export function getTracksFromResult({ result }) {
  if (!isEmpty(result)) {
    if (result.tracks) {
      return result.tracks;
    }
  }
  return null;
}
